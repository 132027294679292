import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../features/RegisterKlSlice";

import useTranslations from "../../i18n/useTranslations";
import { Controller, useForm } from "react-hook-form";
import helper from "services/helper";
import { dateNumber, monthNames, year } from "utility/date";
import { AddressInfo, CheckRef } from "services/Api/Module/Customer";
import _ from "lodash";
import Policy from "pages/consent/Policy";
import liff from "@line/liff";
import ScanQRCode from "components/ScanQRCode";
import { setAddress } from "features/ProfileSlice";
import moment from "moment";
import PolicyTerms from "./PolicyTerms";
import PolicyPrivacy from "./PolicyPrivacy";
import PolicyNews from "./PolicyNews";

const Register = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile.dataUser);
  const stateCustomer = useSelector((state) => state.registerKl.customerData);

  // const [imageProfile, setImageProfile] = useState("");
  const [addressInfo, setAddressInfo] = useState({
    province: [],
    district: [],
    subDistrict: [],
  });
  const [isDrawerConditionOpen, setIsDrawerConditionOpen] = useState(false);
  const [isDrawerPolicyOpen, setIsDrawerPolicyOpen] = useState(false);
  const [isDrawerPromotionOpen, setIsDrawerPromotionOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [refError, setRefError] = useState(false);
  const [refTextError, setRefTextError] = useState('');
  const [hrefValue, setHrefValue] = useState("#");
  // const [maxDate, setMaxDate] = useState('');
  const maxDate = moment().add(-10, "year").format("YYYY-MM-DD"); // 10 ปีที่แล้ว

  // state page pdpd contions ต่างๆ
  let [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") ?? "";
  const isShowPage = !!page ? true : false;
  // const [isShowPage, setIsShowPage] = useState(false);
  const [tootip, setTootip] = useState(false);

  const checkRefId = async (refId) => {
    const refResult = await checkRefCustomer({ referenceId: refId });
    if (refResult.status) {
      setRefError(false);
    } else {
      setRefError(true);
      setRefTextError(refResult.result)
    }
  };

  const checkRefCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null
    };
    try {
      const { data } = await CheckRef({ params: payload });
      const resData = data.resultData;
      if (data.resultCode === "20000") {
        _return.status = true;
      } else {
        _return.status = false
      }
      _return.result = resData;
    } catch (e) {
      console.log(e);
    }
    console.log("🚀 ~ checkRefCustomer ~ _return:", _return)
    return _return;
  };

  const { t } = useTranslations(); // แปลภาษา

  const navigate = useNavigate();

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    // mode:'onTouched',
    defaultValues: {
      pProfileImgUrl: "",
      firstName: "",
      lastName: "",
      mobileNo: "",
      // day: "",
      // month: "",
      // year: "",
      birthDate: "",
      genderId: "",
      address: "",
      province: "",
      district: "",
      subDistrict: "",
      postcode: "",
      referenceId: "",
      isConsentPDPA: false,
      isConsentTAndC: false,
      isConsentNewsAndPromotion: false,

      // lineUid: "",
      // lineName: "",
      // themeSelected: "",
      // isRegisSbSystem: "",
      // currentPoint: "",
      // memberId: "",
    },
  });

  const years = year(2510);

  const birth = getValues("birthDate");
  const birthdateConvert = birth.substring(0, 10);
  // const isoDate = new Date(birth);
  // const formattedDate = isoDate.toISOString().substring(0, 10);

  const onSubmit = (data) => {
    const customerData = { ...data };
    dispatch(setRegister(customerData));
    navigate("/question");
  };

  const getAdderssInfo = async () => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await AddressInfo();
      if (data.resultCode === "20000") {
        const resData = data.resultData;

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log(e);
    }
    return _return;
  };

  useEffect(() => {
    try {
      const dataAddressInfo = getAdderssInfo();
      dataAddressInfo.then((data) => {
        setAddressInfo(data.result);
      });
    } catch (err) {
      console.log(err);
    }
  }, [dispatch]);

  /*---- ดึง state customer ----*/
  useEffect(() => {
    if (stateCustomer) {
      for (let key in stateCustomer) {
        setValue(key, stateCustomer[key]);
      }
    }
    validateForm();
  }, [setValue, stateCustomer]);

  // const validateForm = () => {
  //   const valForm = getValues();
  //   if (
  //     valForm.firstName &&
  //     valForm.lastName &&
  //     valForm.mobileNo.length == 10 &&
  //     valForm.birthDate &&
  //     valForm.genderId &&
  //     valForm.address &&
  //     valForm.province &&
  //     valForm.district &&
  //     valForm.subDistrict &&
  //     valForm.postcode &&
  //     valForm.isConsentPDPA &&
  //     valForm.isConsentTAndC
  //   ) {
  //     setDisabled(false);
  //   } else {
  //     setDisabled(true);
  //   }
  // };

  const validateForm = () => {
    const valForm = getValues();

    let validateFields = [
      "firstName",
      "lastName",
      "mobileNo",
      "birthDate",
      "address",
      "province",
      "district",
      "subDistrict",
      "postcode",
      "isConsentPDPA",
      "isConsentTAndC",
    ]; // เช็คฟิลไหนบ้าง
    let isPass = false;
    for (let k of validateFields) {
      if (!valForm[k]) {
        // เช็ค require
        isPass = false;
        break;
      }

      if (valForm[k]) {
        // เช็คเงื่อนไข อื่น
        if (k === "mobileNo" && valForm[k].length !== 10) {
          // เช็ค require
          isPass = false;
          break;
        }
        if (
          k === "birthDate" &&
          moment(valForm[k]).format("YYYY-MM-DD") > maxDate
        ) {
          isPass = false;
          break;
        }
      }

      isPass = true;
    }
    setDisabled(!isPass);
  };

  // const editProfileImage = async (event) => {
  //   const selectedFile = event.target.files[0];
  //   if (!selectedFile) return;
  //   console.log("selectedFile >>", selectedFile);
  //   const MAX_SIZE_FILE = helper.CONFIG.MAX_SIZE_FILE;
  //   const IMG_TYPE_FILE = helper.CONFIG.IMG_TYPE_FILE;
  //   if (IMG_TYPE_FILE.includes(selectedFile.type)) {
  //     const reader = new FileReader();
  //     reader.onload = async (val) => {
  //       const base64String = btoa(val.target.result);
  //       const base64Small = await helper.IMAGE.toImageSmall(base64String);
  //       // console.log('0base64 Small >>', base64String)
  //       console.log("1base64 Small >>", base64Small);
  //       // "data:image/gif;base64,"
  //       setImageProfile(base64Small);
  //       // setTimeout(()=> setPopupCrop(true), 100)
  //       setValue("pProfileImgUrl", base64Small);
  //     };
  //     reader.readAsBinaryString(selectedFile);
  //   } else if (`${selectedFile.name}`.includes(".HEIC", ".heic")) {
  //     const blob = await heic2any({ blob: selectedFile });

  //     const reader = new FileReader();
  //     reader.onload = async () => {
  //       const base64String = reader.result.split(",")[1];
  //       const base64Small = await helper.IMAGE.toImageSmall(base64String);
  //       setImageProfile(base64Small);
  //     };
  //     reader.readAsDataURL(blob);
  //   } else {
  //     // setImageProfile("No file selected.");
  //     alert(
  //       "Your image file type is not supported. We accept <b>JPG, JPEG and PNG</b> only."
  //     );
  //   }
  // };

  const fixMaxYear = () => {
    const currentDate = new Date();
    const maxDateValue = new Date(
      currentDate.getFullYear() - 10,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    // แปลง maxDateValue เป็นรูปแบบ YYYY-MM-DD
    const formattedMaxDate = maxDateValue.toISOString().split("T")[0];
    const result = formattedMaxDate.toString();
    // กำหนดค่า maxDate
    // setMaxDate(result);
  };

  const handleDrawerClick = (elementId) => {
    switch (elementId) {
      case "btnDrawerCondition":
        const drawerConditionTag = "#drawerCondition";
        setIsDrawerConditionOpen(true);
        setTimeout(() => {
          document
            .querySelector(`${drawerConditionTag} article`)
            .classList.add("active");
        }, 300);
        break;
      case "btnDrawerPolicy":
        const drawerPolicyTag = "#drawerPolicy";
        setIsDrawerPolicyOpen(true);
        setTimeout(() => {
          document
            .querySelector(`${drawerPolicyTag} article`)
            .classList.add("active");
        }, 300);
        break;
      case "btnDrawerPromotion":
        const drawerPromotionTag = "#drawerPromotion";
        setIsDrawerPromotionOpen(true);
        setTimeout(() => {
          document
            .querySelector(`${drawerPromotionTag} article`)
            .classList.add("active");
        }, 300);
        break;
      default:
        document.body.classList.toggle("openedMenu");
    }
  };

  const handleDataFromChild = (data) => {
    // รับข้อมูลจาก child component และทำสิ่งที่ต้องการกับข้อมูลนั้น ในที่นี้จะเซ็ตค่าให้กับ state
    // document.body.classList.toggle("openedMenu");
    const drawerSections = document.querySelectorAll('section[id^="drawer"]');
    drawerSections.forEach((section) => {
      section.style.display = "none";
      section.querySelector("article").classList.remove("active");
    });
    setIsDrawerConditionOpen(data);
    setIsDrawerPolicyOpen(data);
    setIsDrawerPromotionOpen(data);
  };

  const convertAddress = (_stateAddress = {}) => {
    let address = { subDistrict: null, district: null, province: null };
    if (_stateAddress) {
      const subdistrict = _stateAddress.subDistrict.filter(
        (item) => item.id == stateProfile.subDistrict
      );
      address.subDistrict = subdistrict[0]?.id;
      const districtId = subdistrict[0].districtId;

      const district = _stateAddress.district.filter(
        (item) => item.id == districtId
      );
      address.district = district[0].id;
      const provinceId = district[0].provinceId;
      const province = _stateAddress.province.filter(
        (item) => item.id == provinceId
      );
      address.province = province[0].id;
    }

    // dispatch(setAddress(address))
    // setDefaultAddress(address)
    return address;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (stateProfile.isRegisSbSystem === true) {
        // CASE เคยสมัครแร้ว
        navigate("/profile");
      } else if (!stateProfile.isRegisSbSystem) {
        const dataAddressInfo = await getAdderssInfo();
        dispatch(setAddress(dataAddressInfo.result));
        setAddressInfo(dataAddressInfo.result);
        if (stateProfile.firstName) {
          let memberAddress = {};
          if (stateProfile.subDistrict) {
            memberAddress = convertAddress(dataAddressInfo.result);
          }
          const {
            province = null,
            district = null,
            subDistrict = null,
          } = memberAddress;

          // CASE เคยสมัครแร้วใน ระบบเก่า ต้อง Prefield
          setValue("firstName", stateProfile.firstName);
          setValue("lastName", stateProfile.lastName);
          setValue("address", stateProfile.address);
          setValue("mobileNo", stateProfile.mobileNo);
          setValue("genderId", `${stateProfile.genderId}`);
          setValue(
            "birthDate",
            moment(stateProfile.birthDate).format("YYYY-MM-DD")
          );
          setValue("province", province);
          setValue("district", district);
          setValue("subDistrict", subDistrict);
          setValue("postcode", stateProfile.postcode);
        }
        if (stateCustomer) {
          for (let key in stateCustomer) {
            setValue(key, stateCustomer[key]);
          }
        }
      }
    };
    fetchData();
  }, [stateProfile]);

  const styleShow = { display: isShowPage === false ? "block" : "none" };

  return (
    <div>
      <Helmet>
        <title>โคอะลา มาร์ช</title>
        <meta name="title" content="โคอะลา มาร์ช" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="โคอะลา มาร์ช" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="" />
      </Helmet>

      <PolicyTerms
        open={page === "policy-terms"}
        onBack={() => {
          setSearchParams({});
        }}
      />
      <PolicyPrivacy
        open={page === "policy-privacy"}
        onBack={() => {
          setSearchParams({});
        }}
      />
      <PolicyNews
        open={page === "policy-news"}
        onBack={() => {
          setSearchParams({});
        }}
      />

      <div id="pageRegister-step1" style={styleShow}>
        {/* <!-- Content Page --> */}

        <main>
          <div className="wrapContent">
            <section id="pRegisterMain">
              <div className="bRegister">
                <div className="bTitlePage">
                  {/* {page} */}
                  <h1>ลงทะเบียน</h1>
                  <p>กรอกข้อมูลส่วนตัวของคุณ เพื่อใช้ยืนยันในการรับรางวัล</p>
                  <p className="tCondition">
                    โปรดระบุชื่อ-นามสกุลที่ตรงกับบัตรประชาชนเท่านั้น
                  </p>
                </div>
                <div className="bDisplay">
                  {/* <input
                    type="file"
                    id="fileField"
                    name="file"
                    accept="image/*, .heic"
                    hidden="true"
                    onChange={editProfileImage}
                  /> */}

                  <label for="fileField">
                    <div
                      className="bPic"
                      style={{
                        backgroundImage: stateCustomer.lineImageUrl
                          ? `url(${stateCustomer.lineImageUrl})`
                          : "url(https://dummyimage.com/115x100)",
                      }}
                    ></div>
                  </label>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bForm">
                    <div className="bGroup">
                      <div className="bRow">
                        <div
                          className={`control-group validate ${errors.firstName ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">ชื่อ</div>
                          <Controller
                            name="firstName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกชื่อ",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder="กรอกชื่อ"
                                onInput={helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.firstName && (
                            <label htmlFor="" className="tError">
                              {errors.firstName.message}
                            </label>
                          )}
                        </div>
                        <div
                          className={`control-group validate ${errors.lastName ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">นามสกุล</div>
                          <Controller
                            name="lastName"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกนามสกุล",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                placeholder="กรอกนามสกุล"
                                onInput={helper.FNFORM.handleCharOnly}
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  validateForm();
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.lastName && (
                            <label htmlFor="" className="tError">
                              {errors.lastName.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group validate ${errors.mobileNo ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">เบอร์โทรศัพท์</div>
                          <Controller
                            name="mobileNo"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกเบอร์โทรศัพท์",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                data-icon="phone"
                                type="text"
                                inputmode="numeric"
                                placeholder="กรุณากรอกหมายเลขโทรศัพท์ให้ครบ 10 หลัก"
                                onInput={helper.FNFORM.handleNumberOnly}
                                onChange={({ target: { value } }) => {
                                  // if(value.length <10){

                                  // }else if(value.length = 10){
                                  //   onChange(value);
                                  //   validateForm();
                                  // }
                                  if (value.length <= 10) {
                                    onChange(value);
                                    validateForm();
                                  }
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.mobileNo && (
                            <label htmlFor="" className="tError">
                              {errors.mobileNo.message}
                            </label>
                          )}
                        </div>
                      </div>
                      {/* ---ดูเรื่อง การเก็บข้อมูล birthday ใหม่------- */}
                      <div className="bRow">
                        <div className="control-group date validate">
                          <div
                            className={`control-group ${errors.birthDate ||
                              (!!watch("birthDate") &&
                                moment(watch("birthDate")).format(
                                  "YYYY-MM-DD"
                                ) > maxDate)
                              ? "error"
                              : ""
                              }`}
                          >
                            {/* {maxDate} <br/> */}
                            {/* birthDate ::{moment(watch('birthDate')).format('YYYY-MM-DD')} <br/> */}
                            {/* {moment(watch('birthDate')).format('YYYY-MM-DD') > maxDate ?'true':'false'} <br/> */}
                            <div className="tTitle-validate">
                              วัน/เดือน/ปีเกิด
                            </div>
                            <p>อายุต่ำกว่า 10 ปีไม่สามารถเข้าร่วมกิจกรรมได้</p>
                            <Controller
                              name="birthDate"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกวัน",
                                // validate: (value) => (value === watch("password") || true)|| 't.register.form_re_password_validate_2',
                              }}
                              render={({ field: { onChange, ...field } }) => (
                                <input
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    validateForm();
                                  }}
                                  data-icon="birthday"
                                  type="date"
                                  max={maxDate}
                                />
                              )}
                            />

                            <label for="" className="tError">
                              {moment(watch("birthDate")).format("YYYY-MM-DD") >
                                maxDate
                                ? "กรณีกรอกอายุต่ำกว่า 10 ปี ไม่สามารถเข้าร่วมกิจกรรมได้"
                                : "กรุณากรอกวันเกิด"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="bRow">
                        <div className="control-group sex validate">
                          <div
                            className={`control-group ${errors.genderId ? "error" : ""
                              }`}
                          >
                            <div className="tTitle">เพศ</div>
                            <div className="gSex">
                              <label className="control control--radio">
                                <Controller
                                  name="genderId"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "กรุณาเลือกเพศ",
                                  }}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="radio"
                                      value="0"
                                      checked={watch("genderId") === "0"}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        validateForm();
                                      }}
                                    />
                                  )}
                                />
                                <span data-icon="male">ชาย</span>
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                <Controller
                                  name="genderId"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "กรุณาเลือกเพศ",
                                  }}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="radio"
                                      value="1"
                                      checked={watch("genderId") === "1"}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        validateForm();
                                      }}
                                    />
                                  )}
                                />
                                <span data-icon="female">หญิง</span>
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                <Controller
                                  name="genderId"
                                  control={control}
                                  defaultValue=""
                                  rules={{
                                    required: "กรุณาเลือกเพศ",
                                  }}
                                  render={({ field }) => (
                                    <input
                                      {...field}
                                      type="radio"
                                      value="2"
                                      checked={watch("genderId") === "2"}
                                      onChange={(e) => {
                                        field.onChange(e.target.value);
                                        validateForm();
                                      }}
                                    />
                                  )}
                                />
                                <span data-icon="none">ไม่ระบุ</span>
                                <div className="control__indicator"></div>
                              </label>
                              {errors.genderId && (
                                <label htmlFor="" className="tError">
                                  {errors.genderId.message}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bGroup">
                      <div className="bRow">
                        <div
                          className={`control-group validate ${errors.address ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">ที่อยู่</div>
                          <p>กรอกที่อยู่เพื่อสิทธิ์รับของรางวัล</p>
                          <Controller
                            name="address"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกที่อยู่",
                            }}
                            render={({ field: { onChange, ...field } }) => (
                              <textarea
                                {...field}
                                rows="3"
                                placeholder="บ้านเลขที่ คอนโดมิเนียม/หมู่บ้าน หมู่ที่ ถนน ซอย"
                                onChange={({ target: { value } }) => {
                                  onChange(value);
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.address && (
                            <label htmlFor="" className="tError">
                              {errors.address.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${errors.province ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">จังหวัด</div>
                          <div className="select">
                            <Controller
                              name="province"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกจังหวัด",
                              }}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("district", "");
                                    setValue("subDistrict", "");
                                    setValue("postcode", "");
                                    validateForm();
                                  }}
                                >
                                  <option value="" disabled>
                                    จังหวัด
                                  </option>
                                  {addressInfo.province.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              )}
                            />
                          </div>
                          {errors.province && (
                            <label htmlFor="" className="tError">
                              {errors.province.message}
                            </label>
                          )}
                        </div>
                        {/* -----อำเภอ---------- */}

                        <div
                          className={`control-group ${errors.district ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">เขต/อำเภอ</div>
                          <div className="select">
                            <Controller
                              name="district"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกเขต/อำเภอ",
                              }}
                              disabled={!watch("province")}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("subDistrict", "");
                                    setValue("postcode", "");
                                    validateForm();
                                    // checkOnChangeField();
                                  }}
                                >
                                  <option value="" disabled>
                                    เขต/อำเภอ
                                  </option>
                                  {addressInfo.district
                                    .filter(
                                      (item) =>
                                        item.provinceId === watch("province")
                                    )
                                    .map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                          </div>
                          {errors.district && (
                            <label htmlFor="" className="tError">
                              {errors.district.message}
                            </label>
                          )}
                        </div>
                      </div>
                      <div className="bRow">
                        <div
                          className={`control-group ${errors.subDistrict ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">แขวง/ตำบล</div>
                          <div className="select">
                            <Controller
                              name="subDistrict"
                              control={control}
                              defaultValue=""
                              rules={{
                                required: "กรุณาเลือกแขวง/ตำบล",
                              }}
                              disabled={!watch("district")}
                              render={({ field: { onChange, ...field } }) => (
                                <select
                                  {...field}
                                  onChange={({ target: { value } }) => {
                                    onChange(value);
                                    setValue("postcode", "");

                                    const { subDistrict } = addressInfo;
                                    const _postcode =
                                      subDistrict.find(
                                        (item) => item.id == value
                                      )?.postCode ?? "";
                                    if (_postcode) {
                                      setValue("postcode", _postcode);
                                    }
                                    validateForm();
                                  }}
                                >
                                  <option value="" disabled>
                                    แขวง/ตำบล
                                  </option>
                                  {addressInfo.subDistrict
                                    .filter(
                                      (item) =>
                                        item.districtId === watch("district")
                                    )
                                    .map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              )}
                            />
                            {errors.subDistrict && (
                              <label htmlFor="" className="tError">
                                {errors.subDistrict.message}
                              </label>
                            )}
                          </div>
                        </div>

                        <div
                          className={`control-group ${errors.postcode ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">รหัสไปรษณีย์</div>
                          <Controller
                            name="postcode"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: "กรุณากรอกรหัสไปรษณีย์",
                            }}
                            disabled={true}
                            render={({ field: { onChange, ...field } }) => (
                              <input
                                {...field}
                                type="text"
                                inputmode="numeric"
                                placeholder="ไปรษณีย์"
                                onInput={helper.FNFORM.handleNumberOnly}
                                onChange={({ target: { value } }) => {
                                  if (value.length <= 5) {
                                    onChange(value);
                                    validateForm();
                                  }
                                  // checkOnChangeField();
                                }}
                              />
                            )}
                          />
                          {errors.postcode && (
                            <label htmlFor="" className="tError">
                              {errors.postcode.message}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bGroup">
                      <div className="bRow">
                        <div
                          className={`control-group ${errors.referenceId || refError ? "error" : ""
                            }`}
                        >
                          <div className="tTitle">รหัสผู้แนะนำ
                            <i class="btnTips titleForm" onClick={() => setTootip(true)}>
                              <div class="bTips" style={{ display: tootip ? 'block' : 'none' }} >
                                <a class="btnCloseTips" onClick={() => setTootip(false)}></a>
                                <span>ชวนเพื่อนมาเป็นสมาชิก รับ Point เพิ่ม</span>
                                <p>
                                  ชวนเพื่อนมาสมัครด้วยการเปิด QR code ให้เพื่อนสแกน หรือ ก็อปปี้เลขไอดี ของตัวเอง ส่งให้เพื่อนกรอก
                                </p>
                                <p>
                                  เลือกวิธีไหนก็ได้ คุณจะได้รับ point เพิ่มทันที ยิ่งชวนเพื่อนเยอะ ยิ่งได้ Point เยอะ ชวนเพื่อนเลย!
                                </p>
                                <p>
                                  (จำกัดการชวนเพื่อนมาเป็นสมาชิกสูงสุด 10 ท่าน /ต่อ 1 แอคเค้าท์ผู้ใช้งาน)
                                </p>
                              </div>
                            </i>
                          </div>
                          <div className="gCamera">
                            <Controller
                              name="referenceId"
                              control={control}
                              defaultValue=""
                              // onInput={helper.FNFORM.handleEngCharOnly}
                              render={({ field: { onChange, ...field } }) => (
                                <input
                                  {...field}
                                  type="text"
                                  // inputmode="numeric"
                                  placeholder="KMXXXXX"
                                  onInput={helper.FNFORM.handleCharEngOnly}
                                  onChange={({ target: { value } }) => {
                                    if (value.length <= 7) {
                                      if (
                                        value.length < 7 &&
                                        value.length > 0
                                      ) {
                                        setRefError(true);
                                      } else if (value.length === 7) {
                                        checkRefId(value);
                                      } else {
                                        setRefError(false);
                                      }
                                      validateForm();
                                      onChange(value);
                                    }
                                  }}
                                />
                              )}
                            />
                            {/* <div className="bBtn">
                              <button id="btnCamera" type="button" onClick={()=> {
                                alert("AAA")
                              }}></button>
                            </div> */}
                            <ScanQRCode
                              onSave={(v) => {
                                if (v.length === 0) {
                                  setValue("referenceId", "");
                                  setRefError(false);
                                } else {
                                  if (v.length <= 7) {
                                    if (v.length > 0) {
                                      checkRefId(v);

                                      setRefError(true);
                                      setValue("referenceId", v);
                                    } else {
                                      setRefError(false);
                                    }
                                  }
                                }
                              }}
                              onError={(v) => {
                                setValue("referenceId", "");
                              }}
                            />
                            {refError && (
                              <label htmlFor="" className="tError">
                                {/* {"รหัสผู้แนะนำไม่ถูกต้อง"} */}
                                {refTextError}
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bGroup">
                      <div className="bRow">
                        <div
                          className={`control-group pdpa ${errors.isConsentPDPA ? "error" : ""
                            }`}
                        >
                          <label className="control control--checkbox">
                            <p>
                              คุณยอมรับ<span>*</span>{" "}
                              <a
                                id="btnDrawerCondition"
                                // href={hrefValue}
                                onClick={(e) => {
                                  // handleDrawerClick(e.target.id);
                                  // alert("ข้อตกลงและเงื่อนไข")
                                  setSearchParams({ page: "policy-terms" });
                                }}
                              >
                                ข้อตกลงและเงื่อนไข
                              </a>
                            </p>
                            <Controller
                              name="isConsentPDPA"
                              control={control}
                              defaultValue=""
                              rules={{
                                required:
                                  "จำเป็นต้องยินยอมรับเงื่อนไขของการเป็นสมาชิก",
                              }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="checkbox"
                                  checked={watch("isConsentPDPA")}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    validateForm();
                                  }}
                                />
                              )}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          {errors.isConsentPDPA && (
                            <label htmlFor="" className="tError">
                              {errors.isConsentPDPA.message}
                            </label>
                          )}
                        </div>
                      </div>
                      {/* -------------------------- */}

                      <div className="bRow">
                        <div
                          className={`control-group pdpa ${errors.isConsentTAndC ? "error" : ""
                            }`}
                        >
                          <label className="control control--checkbox">
                            <p>
                              คุณยอมรับ<span>*</span>{" "}
                              <a
                                id="btnDrawerPolicy"
                                // href={hrefValue}
                                onClick={(e) => {
                                  // handleDrawerClick(e.target.id);
                                  // alert("นโยบายความเป็นส่วนตัว")
                                  setSearchParams({ page: "policy-privacy" });
                                }}
                              >
                                นโยบายความเป็นส่วนตัว
                              </a>
                            </p>
                            <Controller
                              name="isConsentTAndC"
                              control={control}
                              defaultValue=""
                              rules={{
                                required:
                                  "จำเป็นต้องยินยอมรับเงื่อนไขของการเป็นสมาชิก",
                              }}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="checkbox"
                                  checked={watch("isConsentTAndC")}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    validateForm();
                                  }}
                                />
                              )}
                            />
                            <div className="control__indicator"></div>
                          </label>
                          {errors.isConsentTAndC && (
                            <label htmlFor="" className="tError">
                              {errors.isConsentTAndC.message}
                            </label>
                          )}
                        </div>
                      </div>

                      {/* ----------------------------------------- */}
                      {/* <div className="bRow">
                        <div className="control-group pdpa">
                          <label className="control control--checkbox">
                            <p>
                              คุณยอมรับ{" "}
                              <a
                                id="btnDrawerPromotion"
                                // href={hrefValue}
                                onClick={(e) => {
                                  // handleDrawerClick(e.target.id);
                                  // alert("ข่าวสารและโปรโมชั่น")
                                  setSearchParams({ page: "policy-news" });
                                }}
                              >
                                ข่าวสารและโปรโมชั่น
                              </a>
                            </p>

                            <Controller
                              name="isConsentNewsAndPromotion"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <input
                                  {...field}
                                  type="checkbox"
                                  checked={watch("isConsentNewsAndPromotion")}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                />
                              )}
                            />
                            <div className="control__indicator"></div>
                          </label>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="bStepBtn">
                    <div className="bStep">
                      <div className="bBullet">
                        <i></i>
                        <i></i>
                        <i></i>
                      </div>
                      <p>มีอีก 2 ขั้นตอนให้คุณร่วมตอบกับเรา</p>
                    </div>
                    <div className="bBtn">
                      <button className="btn" disabled={disabled || refError}>
                        ยืนยัน
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </main>

        <Policy
          isDrawerCondition={isDrawerConditionOpen}
          isDrawerPolicy={isDrawerPolicyOpen}
          isDrawerPromotion={isDrawerPromotionOpen}
          sendDataToParent={handleDataFromChild}
        ></Policy>
      </div>
    </div>
  );
};

export default Register;
